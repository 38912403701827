/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Noto+Sans');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons');
@import '@angular/material/theming';

body {
  background-color: rgb(247, 245, 245) !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4{
  font-size: 1.5rem;

}
h5{
  font-size: 1.25rem;
  font-weight: 300;
}
h6 {
  font-size: 1rem;
}

thead{
  border-bottom: 3px solid #F2F2F2;
}
.mat-header-cell {
  font-size: 1rem;
}
p, div, span{
  font-family: 'Noto Sans', sans-serif;
  font-size: 0.9rem;
}

td {
  font-family: 'Noto Sans', sans-serif !important;
}

a {
  color: theme-color('primary');
}
a:hover {
  color: theme-color('primary');
}

.panel {
  background-color: #ffffff;
  margin-bottom: 20px;
  border: 1px solid #e6ecf5;
  border-radius: 5px;
  padding: 20px;
}

.panel-card {
  background-color: #ffffff;
  margin-bottom: 20px;
  border: 1px solid #e6ecf5;
  border-radius: 0;
  padding: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.text-white {
  color: theme-color('white') !important;
}

.bg-white {
  background-color: theme-color('white') !important;
}

.bg-secondary {
  background-color: theme-color('secondary') !important;
}

.mat-elevation-z8 {
  box-shadow: none!important;
}


// MATERIAL CONFIG

.custom-slider .ng5-slider-full-bar .ng5-slider-bar {
  height: 1px!important;
}

.bread-crumbs {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sub-title {
  border-bottom: solid 2px theme-color('primary');
  color: theme-color('text-color');
  margin-bottom: 20px;
}

.swal-title {
  font-size: 1rem !important;
  font-family: 'Montserrat', sans-serif;
}
.swal-input {
  font-size: 0.85rem !important;
  font-family: 'Montserrat', sans-serif;
}

.logo-report {
  width: 160px;
}

.modal-dialog .mat-dialog-container {
  max-width: 80vw !important;
  max-height: 80vh !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
.mat-mdc-form-field-focus-overlay{
  background-color: transparent !important;
}
